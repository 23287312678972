import React, { useEffect, useRef, useState } from "react"
import "intersection-observer"

interface IntersectionProps {
    once?: boolean
    threshold?: number
    onChange?: (isIntersecting: boolean) => void
    className?: string
    render?: (props: { active: boolean }) => JSX.Element
    children?: React.ReactNode
}

export const Intersection: React.FC<IntersectionProps> = ({ once, threshold = 0.15, onChange, className, render: Render, children }) => {
    const [isIntersecting, setIsIntersecting] = useState(false)
    const ref = useRef<HTMLDivElement | null>(null)
    const intRef = useRef(isIntersecting)
    intRef.current = isIntersecting

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting !== intRef.current) {
                        setIsIntersecting(entry.isIntersecting)
                        if (onChange) {
                            onChange(entry.isIntersecting)
                        }
                        if (once && entry.isIntersecting) {
                            observer.disconnect()
                        }
                    }
                })
            },
            { rootMargin: "0px 0px", threshold }
        )

        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => observer.disconnect()
    }, [threshold, onChange, once])

    const child = Render ? <Render active={isIntersecting} /> : children

    return (
        <div className={className} ref={ref} data-active={isIntersecting || undefined}>
            {child}
        </div>
    )
}
